<template>
  <div class="footer">
    
    <div class="item">
      <router-link to="/home" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/home' ? require('@/assets/images/footer/home_a.png') : require('@/assets/images/footer/home.png')"
          alt="">
        首页
      </router-link>
    </div>
    
    <div class="item">
      <router-link to="/" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/' ? require('@/assets/images/footer/guarantee_a.png') : require('@/assets/images/footer/guarantee.png')"
          alt="">
          保障业务
      </router-link>
    </div>

    <div class="item">
      <router-link to="/member" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/member' ? require('@/assets/images/footer/member_a.png') : require('@/assets/images/footer/member.png')"
          alt="">
          会员服务
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
.footer {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  background-color: #fff;
  border-top: 0.5px solid #eee;
  height: 2.2667rem;
  padding-top: 12px;

  .item {
    display: flex;
    justify-content: center;

    img {
      width: .6133rem;
      height: .6133rem;
      margin-bottom: 5px;
    }

    .nav_item {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
}
</style>