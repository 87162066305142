<template>
  <div class="member">



    <div class="content">
      <!-- 顶部 -->
      <Header titleName="会员服务" />

      <!-- 轮播 -->
      <van-swipe :autoplay="3000" :loop="true" @change="onChange2">
        <van-swipe-item v-for="(item, index) in data.swiper" :key="index" @click="toBannerDetails(item.linkUrl)">
          <img :src="item.filePath" alt="">
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <div class="box" v-for="(item, index) in data.swiper" :key="index"
              :class="index == data.current ? 'active' : ''">
            </div>
          </div>
        </template>
      </van-swipe>

      <!--      <div class="btn_view">
        <div class="btn_view_title">
          <img src="@/assets/images/member/btn_title_bg.png" alt="">
          <div class="text"> <span class="cred">会员</span>服务专区</div>
        </div>
        <div class="btns">
          <div class="btn pink" @click="toVideoList">
            <img src="@/assets/images/member/sph.png" alt="">
            <div>视频号</div>
          </div>
          <div class="btn blue" @click="toLive">
            <img src="@/assets/images/member/zb.png" alt="">
            <div>直播专区</div>
          </div>
        </div>

      </div> -->



      <div class="news">
        <div class="btn_view">
          <div class="btn_view_title">
            <img src="@/assets/images/member/news_title_bg.png" alt="">
            <div class="text"> <span class="cred">会员</span>服务专区</div>
          </div>
          <div class="btns">
            <div class="btn" @click="toVideoList">
              <img src="@/assets/images/member/sph.png" alt="">
              <div>视频号</div>
            </div>
            <div class="btn" @click="toLive">
              <img src="@/assets/images/member/zb.png" alt="">
              <div>直播专区</div>
            </div>
            <div class="btn" @click="toMemberNewsList">
              <img src="@/assets/images/member/hyhd.png" alt="">
              <div>会员活动</div>
            </div>
            <div class="btn" @click="toNewDetails(491)">
              <img src="@/assets/images/member/jzww.png" alt="">
              <div>救助慰问</div>
            </div>
          </div>

        </div>
        <div class="news_list">
          <div v-for="(item, index) in data.newsList" :key="index" :class="item.imgUrl ? 'space_between' : ''"
            @click="toNewDetails(item.id)">
            <img :src="item.imgUrl" alt="" v-if="item.imgUrl">
            <div :class="item.imgUrl ? 'right_box' : ''">
              <div style="font-size: .4267rem;margin-bottom: .16rem;height: 2rem;overflow: hidden;text-align: justify;">
                {{
                  item.title.slice(0, 20) + '...' }}</div>
              <div style="color: #ACACAC;" :style="item.imgUrl ? 'font-size: .28rem;' : 'font-size: .32rem;'">
                <span style="margin-right: .2rem;">{{ filterTime(item.addTime) }}</span>
                <span>浏览次数：{{ item.click }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>



    <!-- 底部tab切换 -->
    <Footer />
  </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted, computed } from "vue";
import Header from "../../components/Header.vue";
import { useRoute, useRouter } from 'vue-router'
import Api from "../../utils/api";
import { jsToCallApp } from '../../common/js/callApp1.0.0'
const route = useRoute()
const router = useRouter()

let data = reactive({
  swiper: [],
  current: 0,
  newsList: [],
  //26.互助周报
  hzzbPostData: {
    categoryId: 26,
    channelId: 1,
    pageSize: 1,
    pageIndex: 1,
  },
  //23.互助健康行
  hzjkxPostData: {
    categoryId: 23,
    channelId: 1,
    pageSize: 1,
    pageIndex: 1,
  },
  //24.互助保障杯
  hzbzbPostData: {
    categoryId: 24,
    channelId: 1,
    pageSize: 1,
    pageIndex: 1,
  },
  //25.互助关爱包
  hzgabPostData: {
    categoryId: 25,
    channelId: 1,
    pageSize: 1,
    pageIndex: 1,
  },
});
const onChange2 = (index) => {
  data.current = index;
};
onMounted(() => {
  getBanner()
  getNwesList26()
  shareFn()
});

const shareFn = () => {
  const jsonString = {
    shareUrl: 'https://quanzong.cwmia.com/#/member',//分享链接
    shareCorver: 'https://quanzong.cwmia.com/logo.png',
    shareTitle: '中国职工保障互助会',
    shareDesc: '会员服务',//分享描述
  }
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

function openAppFunction(type, jsonData) {
  const useTimeOut = setTimeout(() => {
    jsToCallApp(
      {
        type: type,
        ...jsonData,
      },
      (data) => {
        console.log(type, data)
      },
    )
    clearTimeout(useTimeOut);
  }, 300);
}
// 获取轮播banner 会员服务
const getBanner = () => {
  Api.getBanner({ name: 'Service' }).then(res => {
    if (res.status == 200) {
      data.swiper = res.data.banner
    }

  })
}
// 活动专区 - 新闻列表
const getNwesList23 = () => {
  //23.互助健康行
  Api.articleViewChannelNew(data.hzjkxPostData).then(res => {
    if (res.status == 200) {
      data.newsList = [...data.newsList, ...res.data]
      getNwesList24()
    }
  })
}
const getNwesList24 = () => {
  //24.互助保障杯
  Api.articleViewChannelNew(data.hzbzbPostData).then(res => {
    if (res.status == 200) {
      data.newsList = [...data.newsList, ...res.data]
      getNwesList25()
    }
  })
}
const getNwesList25 = () => {
  //25.互助关爱包
  Api.articleViewChannelNew(data.hzgabPostData).then(res => {
    if (res.status == 200) {
      data.newsList = [...data.newsList, ...res.data]
    }
  })
}
const getNwesList26 = () => {
  //26.互助周报
  Api.articleViewChannelNew(data.hzzbPostData).then(res => {
    if (res.status == 200) {
      data.newsList = [...data.newsList, ...res.data]
      getNwesList23()
    }
  })
}

const filterTime = computed(() => (timestamp) => {
  timestamp = timestamp.replace(/-/g,'/')
  let time = new Date(timestamp)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()


  if (month < 10) { month = '0' + month }
  if (date < 10) { date = '0' + date }

  return year + '-' + month + '-' + date
})

// 、、跳转新闻列表
const toMemberNewsList = () => {
  router.push({ path: '/member/activity' })
};
// 、、跳转新闻详情
const toNewDetails = (id) => {
  router.push({ path: '/news/details', query: { id, } })
};
// 、、跳转轮播图详情
const toBannerDetails = (url) => {
  window.location.href = url
};

// 跳转到视频号列表
const toVideoList = () => {
  router.push({ path: '/member/videoList' })
};
// 跳转到直播页面
const toLive = () => {
  router.push({ path: '/member/live' })
};
</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}



.member {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;

  .content {
    flex: 1;
    overflow-y: auto;

    .van-swipe {
      height: 6.6667rem;
      position: relative;
      margin-bottom: .5067rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .custom-indicator {

      position: absolute;
      bottom: .1333rem;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;

      .box {
        width: .1067rem;
        height: .1067rem;
        border-radius: .1067rem;
        background-color: #fff;
        margin-right: 3px;
      }

      .active {
        width: .2533rem;
      }
    }

    .right_img {
      width: .16rem;
    }



    .news {
      background-color: #fff;
      padding: 0 .4rem;
      margin: 0 .4rem;
      border-radius: .1333rem;
      margin-bottom: .2667rem;

      .news_view_title {
        width: 4.92rem;
        height: 1.2rem;
        position: relative;
        margin: 0 auto;
        font-size: .4933rem;
        font-weight: 600;
        margin-bottom: .4rem;

        .news_title_bg {
          width: 100%;
          position: absolute;
          left: 0;
          top: -0.0667rem;
        }

        .text {
          width: 100%;
          height: 100%;
          text-align: center;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .news_list {
        margin-top: .2rem;

        >div {
          padding: .4rem 0;
          border-bottom: .0133rem #ececec solid;

          &:last-child {
            border: none;
          }

          img {
            width: 3.0667rem;
            height: 2.3067rem;
            object-fit: cover;
            margin-right: .3733rem;
            border-radius: .1333rem;
          }
        }
      }

      .right_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 2.3067rem;
        width: calc(100% - 3.5rem);
      }
    }

    .cred {
      color: #D80010;
      font-weight: bold;
    }

    .msg {
      background-color: #fff;
      padding: .5067rem .4rem;
      padding-top: .1rem;
      margin-bottom: .2667rem;

      .dt {
        width: 100%;

        img {
          width: 100%;
        }
      }

      p {
        text-indent: .7rem;
        font-size: .4rem;
        color: #707070;
        line-height: .8267rem;

      }
    }


    .btn_view {
      width: 100%;
      height: 4.5rem;
      margin: 0 auto;
      border-bottom: 1px solid #E2E2E2;

      .btn_view_title {
        width: 4.92rem;
        height: 1.2rem;
        position: relative;
        margin: 0 auto;
        font-size: .4933rem;
        font-weight: 600;
        margin-bottom: .4rem;

        img {
          width: 100%;
          position: absolute;
          left: 0;
          top: -0.0667rem;
        }

        .text {
          width: 100%;
          height: 100%;
          text-align: center;
          padding-top: .2rem;
          position: relative;
          z-index: 2;
        }
      }

      .btns {
        display: flex;
        justify-content: space-between;
      }

      .btn {
        width: 3.9733rem;
        height: 1.6933rem;
        border-radius: .1333rem;
        flex: 4;
        font-size: .40rem;
        text-align: center;
        margin: 0 .2rem;
        color: #333333;

        img {
          width: 1.5rem;
          margin-bottom: .2rem;
        }
      }

      .pink {
        background: linear-gradient(180deg, rgba(254, 180, 179, .39) 0%, rgba(254, 248, 223, .39) 100%);
      }

      .blue {
        background: linear-gradient(180deg, rgba(201, 243, 242, .39) 0%, rgba(245, 255, 253, .39) 100%);
      }
    }



  }
}
</style>